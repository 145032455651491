import React from 'react';
import {Skeleton} from 'antd';
import { PageHeader as PageHeaderAntd } from '@ant-design/pro-layout';
import PropTypes from 'prop-types';
import {darkModeSelector} from "redux/selectors";
import {useSelector} from "react-redux";

function PageHeader({loading, ...props}) {
  const darkMode = useSelector(darkModeSelector);

  const newExtra = props.extra ? (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.extra.map(e => <div key={e.key} style={{ margin: 2 }}>{e}</div>)}
    </div>
  ) : null;

  return (
    <PageHeaderAntd
    {...props}
      title={
      loading ?
        <Skeleton.Input style={{ width: 300 }} active /> :
        // <Typography.Title style={{fontSize: 32}}>{props.title}</Typography.Title>
        <div style={{color: darkMode ? '#DCDCDC' : '#000'}}>
          {props.title}
        </div>
      }
      extra={newExtra}
    />
  )
}

PageHeader.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
}

PageHeader.defaultProps = {
  style: { padding: 0, marginBottom: 30},
  loading: false
}

export default PageHeader;
