import React, { useEffect } from 'react'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { Modal, Typography } from 'antd'
import { UpCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import { checkPermission } from 'redux/user/selectors'
import { Cancel, Upgrade } from 'components/UI'
import { isFeatureChampion, isFeatureElite } from 'utils/helper'
import { billingPosSelector, billingSelector } from 'redux/selectors'
import './style.scss'

const UpgradeBilling = injectIntl(({ label, isPos, intl }) => {
  const isHasAccess = useSelector((state) => checkPermission(state, 'billing'))
  const dispatch = useDispatch()

  const onPush = (path) => dispatch(push(path))

  return (
    <div className="text-center text-wrapper">
      <Typography.Title level={5} className="text-center mb-3">{label}</Typography.Title>
      {isPos && (
        <Cancel
          title={intl.formatMessage({ id: 'billing.renew_pos' })}
          onClick={() => onPush('/settings/billing/pos?isRenew=1')}
          icon={<UpCircleOutlined />}
        />
      )}
      {isHasAccess && !isPos && (
        <Upgrade
          title={intl.formatMessage({ id: 'billing.upgrade_now' })}
          onClick={() => onPush('/settings/billing/upgrade')}
          className="ml-3"
        />
      )}
    </div>
  )
})

// Disable hidden feature
const HideFeature = (props) => {
  const {
    code = '',
    feature = '',
    intl,
    currentCount,
    maxCode,
    maxFeature = '',
    forceHiddenFeature,
    isFeatureOnlyAccess,
    asModal,
    modalProps = {},
    children = null,
    center,
    onlyPaidPlan,
    isPos,
    byPassCanBeAddoned = false, // Sementara untuk kebutuhan POS
  } = props

  const billing = useSelector(billingSelector)
  const billingPos = useSelector(billingPosSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isPos && billingPos?.status?.id === 0) {
      dispatch(push('/pos'))
    }
  }, [billingPos, dispatch, isPos])

  if (forceHiddenFeature) return children

  const canBeAdOned = byPassCanBeAddoned || billing.can_be_addoned

  let label = intl.formatMessage(
    {
      id: isFeatureOnlyAccess
        ? 'billing.feature_can_only_be_use_on_pro_plan'
        : 'billing.feature_available_in_pro_plan',
    },
    { feature },
  )
  let modalTitle = intl.formatMessage({ id: 'billing.pro_plan_features' })

  const isPosNeedRenew = () => {
    const posStatusId = billingPos?.status?.id

    if (!isPos) return false

    if (posStatusId === 0) return false

    if (billing.plan_id === 1 && posStatusId !== -1) return true

    const trialRenew = dayjs(dayjs()).diff(dayjs(billing.expiry_date))
    const trialPOSRenew = dayjs(dayjs()).diff(dayjs(billingPos.expiry_date))

    // POS TRIAL 14 days and has been expired
    if (posStatusId === -1 && trialPOSRenew > 0) {
      return true
    }

    // LITE POS
    if (posStatusId === 1 && billing?.max_outlets > 0 && trialRenew > 0 && billing.plan_id === 6) {
      return true
    }

    return false
  }

  if (_.isEmpty(billing)) return children

  if (canBeAdOned && maxCode && billing[maxCode] <= currentCount) {
    label = intl.formatMessage(
      { id: 'billing.quota_is_exhausted_add_an_addon' },
      { feature: maxFeature },
    )
  } else if (onlyPaidPlan) {
    label = intl.formatMessage(
      { id: 'billing.feature_can_only_be_accessed_on_paid_plan' },
      { feature },
    )
    modalTitle = intl.formatMessage({ id: 'billing.paid_plan_features' })
  } else if (isFeatureChampion({ billing, code })) {
    label = intl.formatMessage(
      {
        id: isFeatureOnlyAccess
          ? 'billing.feature_can_only_be_use_on_champion_plan'
          : 'billing.feature_available_in_champion_plan',
      },
      { feature },
    )
    modalTitle = intl.formatMessage({ id: 'billing.champion_plan_features' })
  } else if (isFeatureElite({ billing, code })) {
    label = intl.formatMessage(
      {
        id: isFeatureOnlyAccess
          ? 'billing.feature_can_only_be_use_on_elite_plan'
          : 'billing.feature_available_in_elite_plan',
      },
      { feature },
    )
    modalTitle = intl.formatMessage({ id: 'billing.elite_plan_features' })
  } else if (billing.hidden_feature?.filter((e) => e === code).length === 0 && !isPosNeedRenew()) {
    return children
  }

  if (isPosNeedRenew()) {
    label = <FormattedHTMLMessage id="hideFeature.add_pos_addon_or_upgrade" />
  }

  if (asModal) {
    return (
      <Modal
        title={modalTitle}
        footer={[<Cancel key={0} onClick={() => modalProps?.onCancel?.()} />]}
        maskTransitionName=""
        {...modalProps}
      >
        <UpgradeBilling label={label} />
      </Modal>
    )
  }

  return (
    <div className="hide-feature-container">
      {children}
      <div className={`wrapper ${center && 'wrapper-center'}`}>
        <UpgradeBilling label={label} isPos={isPosNeedRenew()} />
      </div>
    </div>
  )
}

export default injectIntl(HideFeature)
