import { useQuery } from 'react-query'
import { message } from 'antd'

import { getFinancePurchaseInvoiceOverview } from 'utils/apis'

export const usePurchaseOverview = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['purchaseOverview', payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinancePurchaseInvoiceOverview(payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled,
      onError: (error) =>
        message.error((error && error.message) || 'Failed to load data from server!'),
    },
  )
}
