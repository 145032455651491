import { message } from 'antd'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  getFinanceExpense,
  getFinanceExpenses,
  getFinanceExpensesAccountChart,
  getFinanceExpensesContactChart,
  getFinanceExpensesOverview,
  getRecurringExpenses,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useExpenses = ({ payload = {}, enabled = false }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== options.expenses_per_page_default) {
    dispatch(
      editOption({
        expenses_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['expense', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceExpenses(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useRecurringExpenses = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['expense', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringExpenses(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useExpenseOverview = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['expense', 'overview', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceExpensesOverview(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useExpense({ id, enabled = false }) {
  return useQuery(
    ['expense', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceExpense(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useExpenseAccountChart(params = {}, options = {}) {
  return useQuery(
    ['expenseAccountChart', params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceExpensesAccountChart(params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useExpenseContactChart(params = {}, options = {}) {
  return useQuery(
    ['expenseContactChart', params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceExpensesContactChart(params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}
