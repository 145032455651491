import axios from 'axios'

export const getBank = () => {
  return axios.get('/banks')
}

export const getBankDetail = (id) => {
  return axios.get(`/banks/${id}`)
}

export const getBankConnect = () => {
  return axios.get('/banks/connects')
}

export const getBankConnectDetail = (id) => {
  return axios.get(`/banks/connects/${id}`)
}

export const getBankConnectAccountTypes = () => {
  return axios.get('/banks/connects/accountTypes')
}

export const addBankConnectSubmission = (data) => {
  return axios.post('/banks/connects/submissions', data)
}

export const addBankConnect = (data) => {
  return axios.post('/banks/connects', data)
}

export const editBankConnectSubmission = (id, data) => {
  return axios.put(`/banks/connects/${id}`, data)
}

export const deleteBankConnectSubmission = (id) => {
  return axios.delete(`/banks/connects/${id}`)
}

export const removeBankBookImage = (url) => {
  return axios.delete('/banks/connects/submissions/bankBookImgs', { data: { url } })
}

export const removeIdCardImage = (url) => {
  return axios.delete('/banks/connects/submissions/idcardImgs', { data: { url } })
}

export const removeBankBookImageDetail = (id) => {
  return axios.delete(`/banks/connects/submissions/${id}/bankBookImgs`)
}

export const removeIdCardImageDetail = (id) => {
  return axios.delete(`/banks/connects/submissions/${id}/idcardImgs`)
}
