/* eslint-disable no-restricted-globals */
import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import NProgress from 'nprogress'
import store from 'store'
import _ from 'lodash'
import { parse } from 'query-string'
import { replace } from 'connected-react-router'

// import Loader from 'components/LayoutComponents/Loader'
import { Helmet } from 'components/UI'
import { isTokenExist, setToken, removeToken } from 'utils/cookies'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { resetInits } from 'redux/inits/actions'
import { inits } from 'redux/actions'
import { hideAccountTransactionReport } from 'redux/finance/actions'
import { logoutRedux } from 'redux/user/actions'
import { setErrorLoginForm } from 'redux/loginForm/actions'
import dayjs from 'dayjs'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import SelectCompanyLayout from './SelectCompany'
import NoCompanyLayout from './Main/NoCompany'
import InfoLayout from './Info'

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  selectCompany: SelectCompanyLayout,
  noCompanyLayout: NoCompanyLayout,
  info: InfoLayout,
}

const avoidRouteWhenAuthorized = [
  '/user/select-company',
  '/user/billing/success',
  '/user/billing/failed',
  '/companies/add',
]
const infoPages = ['/maintenance']
const execludePreviousPages = ['/', '/user/select-company']
const publicPages = ['/user/request-delete-account']

@withRouter
@connect(
  ({ user, options: { options }, companies, billing, finance: { accountTransactionReport } }) => ({
    user,
    options,
    companies,
    expiryDate: billing.expiry_date,
    accountTransactionReport,
  }),
  {
    setSelectedEndpoint,
    inits,
    hideAccountTransactionReport,
    setErrorLoginForm,
    resetInits,
    logoutRedux,
    replacePath: replace,
  },
)
class IndexLayout extends React.PureComponent {
  previousPath = ''

  state = {
    containerKey: 0,
  }

  componentDidMount() {
    const searchs = parse(location.search)

    // if there is error params, show error
    if (searchs.error) {
      this.props.setErrorLoginForm(searchs.error)
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, null, `${location.pathname}${location.hash}`)
    }
    this.loginFromOtherApp()
  }

  componentDidUpdate(prevProps) {
    const { location, user } = this.props
    const { location: prevLocation } = prevProps

    if (location.pathname !== prevLocation.pathname && location.pathname !== '/user/login') {
      if (location.state) {
        if (location.state.resetscroll) {
          window.scrollTo(0, 0)
        }
      } else {
        window.scrollTo(0, 0)
      }
      // Untuk hide modal report accountTransaction
      if (this.props.accountTransactionReport.show) {
        this.props.hideAccountTransactionReport()
      }
    }

    if (prevProps.user.email !== user.email) {
      // set inspectlet identity
      // eslint-disable-next-line no-underscore-dangle
      if (window.__insp) {
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['identify', user.email])
      }
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (
      prevProps.companies.selectedEndpoint !== this.props.companies.selectedEndpoint &&
      prevProps.companies.selectedEndpoint &&
      this.props.companies.selectedEndpoint
    ) {
      this.props.inits().then(() => {
        this.setState({ containerKey: this.props.companies.selectedEndpoint })
      })
    }
    return null
  }

  getLayout = () => {
    const {
      location: { pathname, search },
    } = this.props
    const searchs = parse(search)
    if (_.includes(publicPages, pathname)) {
      return 'public'
    }

    if (_.includes(infoPages, pathname)) {
      return 'info'
    }

    if (pathname === '/user/select-company') {
      return 'selectCompany'
    }
    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'login'
    }

    if (pathname === '/companies/add' && !!searchs.from_login) {
      return 'noCompanyLayout'
    }
    return 'main'
  }

  loginFromOtherApp = () => {
    const { search } = location
    const searchs = parse(search)

    // if there is token params, set token
    if (searchs.token) {
      store.remove('app.previousPathname')
      store.remove('app.endpoint')
      removeToken()
      this.props.setSelectedEndpoint(null)
      this.props.logoutRedux()
      this.props.resetInits()
      setToken(searchs.token)
      // if there is endpoint params, set endpoint
      if (searchs.endpoint) {
        store.set('app.endpoint', searchs.endpoint)
        this.props.setSelectedEndpoint(searchs.endpoint)
      }
      // Hilangkan search params token
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, null, `${location.pathname}${location.hash}`)
    }
  }

  bootstrappedLayout = () => {
    const {
      children,
      location: { pathname },
      user,
      options,
      companies,
      expiryDate,
    } = this.props
    const storeEndpoint = store.get('app.endpoint')
    // Layout Rendering
    const layout = this.getLayout()
    const Container = Layouts[layout]
    const currentLayout = (
      <Container key={this.state.containerKey} companies={companies}>
        {children}
      </Container>
    )

    const isUserAuthorized = isTokenExist()
    const isLoginLayout = layout === 'login'
    const isPublicLayout = layout === 'public'

    if (isPublicLayout) {
      return currentLayout
    }

    // redirect to login page if current is not login page and user not authorized
    if ((!isLoginLayout || pathname === '/user/select-company') && !isUserAuthorized) {
      // Jika tidak ada token dan tidak melakukan logout, hapus localstorage endpoint
      if (!user.logout) {
        store.remove('app.endpoint')
      }
      if (!_.includes(execludePreviousPages, pathname)) {
        store.set('app.previousPathname', pathname)
      } else {
        return <Redirect to="/user/login" />
      }
      return currentLayout
    }
    // Jika sudah login dan belum memilih perushaan (kalau perusahaan lebih dari satu) dan posisinya tidak di billing dan select company
    if (
      isUserAuthorized &&
      !companies.selectedEndpoint &&
      !storeEndpoint &&
      !_.includes(avoidRouteWhenAuthorized, pathname)
    ) {
      if (!isLoginLayout && !_.includes(execludePreviousPages, pathname)) {
        store.set('app.previousPathname', pathname)
      }

      return <Redirect to="/user/select-company" />
    }

    if (!companies.selectedEndpoint && storeEndpoint) {
      this.props.setSelectedEndpoint(storeEndpoint)
    }

    // if user login then initialize inspectlet
    if (isUserAuthorized && (companies.selectedEndpoint || storeEndpoint)) {
      // Initialize inspectlet
      if (process.env.NODE_ENV === 'production') {
        // Initialize inspectlet
        // eslint-disable-next-line func-names
        ;(function () {
          // eslint-disable-next-line no-underscore-dangle
          window.__insp = window.__insp || []
          // eslint-disable-next-line no-undef
          __insp.push(['wid', 476925044])
          // eslint-disable-next-line prefer-const, func-names
          let ldinsp = function () {
            // eslint-disable-next-line no-underscore-dangle, prefer-const, eqeqeq, yoda, prefer-template
            if (typeof window.__inspld != 'undefined') return
            window.__inspld = 1
            const insp = document.createElement('script')
            insp.type = 'text/javascript'
            insp.async = true
            insp.id = 'inspsync'
            insp.src = `${document.location.protocol === 'https:' ? 'https' : 'http'}
              '://cdn.inspectlet.com/inspectlet.js?wid=476925044&r='
              ${Math.floor(new Date().getTime() / 3600000)}`
            const x = document.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(insp, x)
          }
          setTimeout(ldinsp, 0)
        })()
      }
    }

    const isExpired = expiryDate ? dayjs(expiryDate).isBefore(dayjs()) : false
    // dayjs().subtract(1, 'days').isAfter(expiryDate)
    // Jika sudah login dan expired serta sudah ada endpoint
    if (isUserAuthorized && !isLoginLayout && (companies.selectedEndpoint || storeEndpoint)) {
      if (isExpired && !pathname.includes('/billing')) {
        return <Redirect to="/settings/billing" />
      }
    }

    // Redirect ke onboarding jika onboarding 1
    if (
      isUserAuthorized &&
      !isLoginLayout &&
      options.onboarding &&
      pathname !== '/onboarding' &&
      (companies.selectedEndpoint || storeEndpoint)
    ) {
      if (!isExpired) {
        return <Redirect to="/onboarding" />
      }
    }

    // redirect to main dashboard when user on login page or select company page and authorized
    if (
      (isLoginLayout || pathname === '/user/select-company') &&
      !/^\/user\/invite(?=\/|$)/i.test(pathname) &&
      pathname !== '/user/billing/success' &&
      pathname !== '/user/billing/failed' &&
      isUserAuthorized &&
      (companies.selectedEndpoint || store.get('app.endpoint'))
    ) {
      if (store.get('app.previousPathname')) {
        return <Redirect to={store.get('app.previousPathname')} />
      }

      return <Redirect to="/dashboard" />
    }

    // in other case render previously set layout
    return currentLayout
  }

  render() {
    return (
      <Fragment>
        <Helmet titleTemplate="Kledo | %s" title="Kledo Software" />
        {this.bootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
