import { useMutation, useQuery } from 'react-query'
import { message } from 'antd'

import { getUsers } from 'utils/apis/users'
import { loginWithSso } from 'utils/apis/user'

export function useUsers({ payload, options = {} } = {}) {
  return useQuery(
    ['users', payload],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getUsers(payload)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useLoginWithSsoMutation = () => {
  return useMutation(loginWithSso)
}
