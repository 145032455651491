import axios from 'axios'
import { stringify } from 'query-string'

import { cleanBlankValue, getOnce } from 'utils/helper'

export const getFinancePurchaseDeliverys = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/purchaseDeliveries?${query}`)
}

export const addFinancePurchaseDelivery = (data) => {
  return axios.post('/finance/purchaseDeliveries', data)
}

export const editFinancePurchaseDelivery = (data) => {
  return axios.put(`/finance/purchaseDeliveries/${data.id}`, data)
}

export const deleteOnAddFinancePurchaseDeliveryAttachment = (url) => {
  return axios.delete('/finance/purchaseDeliveries/attachments', { data: { url } })
}

export const deleteOnEditFinancePurchaseDeliveryAttachment = (id, url) => {
  return axios.delete(`/finance/purchaseDeliveries/${id}/attachments`, { data: { url } })
}

export const getFinancePurchaseDelivery = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseDeliveries/${id}${query}`)
}

export const getFinancePurchaseDeliveryLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseDeliveries/${id}/logs${query}`)
}

// export const changeFinancePurchaseDeliveryStatus = (id, data) => {
//   return axios.patch(`/finance/purchaseDeliveries/${id}/status`, data)
// }

export const deleteFinancePurchaseDelivery = (id) => {
  return axios.delete(`/finance/purchaseDeliveries/${id}`)
}

export const getFinancePurchaseDeliveryMerged = (params) => {
  const query = stringify(params)

  return axios.get(`/finance/purchaseDeliveries/merged?${query}`)
}

// export const getFinancePurchaseDeliveryPublicUrl = (id) => {
//   return axios.get(`/finance/purchaseDeliveries/${id}/publicUrl`)
// }

export const getFinancePurchaseDeliveryPublicUrl = (id) => {
  return axios.get(`/finance/purchaseDeliveries/${id}/publicUrl`)
}

export const exportFinancePurchaseDelivery = (params) => {
  params = cleanBlankValue(params)
  const query = stringify(params)
  return axios.get(`/finance/purchaseDeliveries/export?${query}`, { responseType: 'arraybuffer' })
}

export const getFinancePurchaseDeliveryWaTemplate = (id) => {
  return axios.get(`/finance/purchaseDeliveries/${id}/whatsapp`)
}

export const getFinancePurchaseDeliveryEmailTemplate = (id) => {
  return axios.get(`/finance/purchaseDeliveries/${id}/email`)
}

export const sendFinancePurchaseDeliveryEmail = (id, data) => {
  return axios.post(`/finance/purchaseDeliveries/${id}/email`, data)
}

export const getFinancePurchaseDeliverySmsTemplate = (id) => {
  return axios.get(`/finance/purchaseDeliveries/${id}/sms`)
}

export const sendFinancePurchaseDeliverySms = (id, data) => {
  return axios.post(`/finance/purchaseDeliveries/${id}/sms`, data)
}

export const inputMassDeleteFinancePurchaseDelivery = (data) => {
  return axios.post('/finance/purchaseDeliveries/inputMassDelete', data)
}

export const executeMassDeleteFinancePurchaseDelivery = (data) => {
  return axios.delete('/finance/purchaseDeliveries/executeMassDelete', { data })
}

export const inputMassCreateFinancePurchaseDelivery = (data) => {
  return axios.post('/finance/purchaseDeliveries/inputMassPurchaseDelivery', data)
}

export const executeMassCreateFinancePurchaseDelivery = (data) => {
  return axios.post('/finance/purchaseDeliveries/executeMassPurchaseDelivery', data)
}
