import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Select } from 'antd'
import { injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { loadedInitSelector, optionsSelector, userSelector } from 'redux/selectors'
// import { getUsers } from 'utils/apis'
// import { filterOption } from 'utils/helper'
import { useTerm } from 'utils/hooks'
import { usePosUsers, useUsers } from 'utils/queries'
import { checkPermission } from 'redux/user/selectors'

const UserSuggestion = ({
  intl,
  mode = 'single',
  innerRef,
  disabled,
  defaultUser,
  onSelect,
  rules,
  hideTagOnFirstLoading,
  isReadyToFetch = false,
  withPermission = true,
  hideLabel,
  excludeUsers,
  isPos,
  ...props
}) => {
  const loadedInit = useSelector(loadedInitSelector)
  const options = useSelector(optionsSelector)
  const activeUser = useSelector(userSelector)
  const isOtherSalesPersonAccessPermission = useSelector((state) =>
    checkPermission(state, 'other_sales_person_access'),
  )
  const isOtherSalesPersonAccess = withPermission ? isOtherSalesPersonAccessPermission : true

  const [open, setOpen] = useState(false)
  const [enabledQuery, setEnabledQuery] = useState(false)

  useEffect(() => {
    if ((open || isReadyToFetch) && isOtherSalesPersonAccess && !enabledQuery) setEnabledQuery(true)
  }, [enabledQuery, isOtherSalesPersonAccess, isReadyToFetch, open])

  const fetcher = isPos ? usePosUsers : useUsers

  const { data, isLoading } = fetcher({
    options: { enabled: enabledQuery },
  })
  const term = useTerm({ intl })

  const makeDefaultUser = useMemo(() => {
    return _.isEmpty(defaultUser) ? activeUser : defaultUser
  }, [activeUser, defaultUser])

  // React.useEffect(() => {
  //   if (defaultUser) {
  //     if (mode === 'multiple') {
  //       setUsers(defaultUser)
  //     } else {
  //       setUsers([defaultUser])
  //     }
  //   }
  // }, [defaultUser, mode])

  const formattedOptions = useMemo(() => {
    let filteredData = !_.isEmpty(makeDefaultUser) ? [makeDefaultUser] : []
    if (!isOtherSalesPersonAccess || !data?.data) return filteredData

    if (Array.isArray(data?.data)) filteredData = data.data.filter((user) => user.is_active)

    if (excludeUsers) filteredData = filteredData.filter((user) => !excludeUsers.includes(user.id))

    return filteredData
  }, [data, excludeUsers, isOtherSalesPersonAccess, makeDefaultUser])

  const selectedHandler = useCallback(
    (value) => {
      const findData = formattedOptions.find((row) => row.id === value)
      if (typeof onSelect === 'function') {
        onSelect(value, formattedOptions, findData)
      }
    },
    [formattedOptions, onSelect],
  )

  const dropdownVisibleChangeHandler = useCallback((visible) => {
    setOpen(visible)
  }, [])

  const filterOptionHandler = useCallback((input, option) => {
    if (option.name) {
      return option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return false
  }, [])

  if (!options.sales_person) return <></>

  return (
    <Form.Item
      label={!hideLabel ? term.sales_person : null}
      name={props.name ?? 'sales_id'}
      rules={rules}
    >
      <Select
        ref={innerRef}
        showSearch
        mode={mode === 'multiple' ? 'multiple' : undefined}
        placeholder={intl.formatMessage(
          { id: 'salesPerson.select_sales_person_with_title' },
          {
            sales_person: term.sales_person,
          },
        )}
        disabled={disabled}
        onSelect={selectedHandler}
        loading={isLoading}
        onDropdownVisibleChange={dropdownVisibleChangeHandler}
        allowClear
        tagRender={
          hideTagOnFirstLoading && (!loadedInit || isLoading || !enabledQuery)
            ? () => null
            : undefined
        }
        options={formattedOptions}
        fieldNames={{ label: 'name', value: 'id' }}
        filterOption={filterOptionHandler}
        {...props}
      />
    </Form.Item>
  )
}

const Component = injectIntl(UserSuggestion)

export default React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
