import * as api from 'utils/apis/financeTax'
import * as actionTypes from 'redux/actionTypes'

export const setFinanceTaxes = (payload) => {
  return {
    type: actionTypes.SET_FINANCE_TAXES,
    payload,
  }
}

export const addFinanceTaxRedux = (payload) => {
  return {
    type: actionTypes.ADD_FINANCE_TAX,
    payload,
  }
}

export const editFinanceTaxRedux = (payload) => {
  return {
    type: actionTypes.EDIT_FINANCE_TAX,
    payload,
  }
}

export const addFinanceTax = (payload) => {
  return (dispatch) => {
    return api.addFinanceTax(payload).then((response) => {
      dispatch(addFinanceTaxRedux(response.data.data))
      return response
    })
  }
}

export const editFinanceTax = (payload) => {
  return (dispatch) => {
    return api.editFinanceTax(payload).then((response) => {
      dispatch(editFinanceTaxRedux(response.data.data))
      return response
    })
  }
}

export const moveFinanceTaxRedux = (payload) => {
  return {
    type: actionTypes.MOVE_FINANCE_TAX,
    payload,
  }
}

export const moveFinanceTax = (payload) => {
  return (dispatch) => {
    dispatch(moveFinanceTaxRedux(payload))

    return api
      .moveFinanceTax(payload.srcId, payload.destId)
      .then((response) => {
        return response
      })
      .catch(() => {
        dispatch(
          moveFinanceTaxRedux({
            dragIndex: payload.hoverIndex,
            hoverIndex: payload.dragIndex,
            srcId: payload.destId,
            destId: payload.srcId,
          }),
        )
      })
  }
}

export const changeStatusFinanceTaxRedux = (payload) => {
  return {
    type: actionTypes.CHANGE_STATUS_FINANCE_TAX,
    payload,
  }
}

export const changeStatusFinanceTax = (payload) => {
  return (dispatch) => {
    dispatch(changeStatusFinanceTaxRedux(payload))

    if (payload.checked) {
      return api.activateFinanceTax(payload.id)
    }

    return api.deactivateFinanceTax(payload.id)
  }
}

export const filterFinanceTax = (payload) => {
  return {
    type: actionTypes.FILTER_FINANCE_TAX,
    payload,
  }
}

export const deleteFinanceTaxRedux = (payload) => {
  return {
    type: actionTypes.DELETE_FINANCE_TAX,
    payload,
  }
}

export const deleteFinanceTax = (payload) => {
  return (dispatch) => {
    return api.deleteFinanceTax(payload.id).then((response) => {
      dispatch(deleteFinanceTaxRedux(payload))
      return response
    })
  }
}
