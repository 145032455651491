import { message } from 'antd'
import store from 'store'

import {
  acceptInvitation as onAcceptInvitation,
  invite as onInvite,
  login as onLogin,
  logout as onLogout,
  reset as onReset,
  singleLogin as onSingleLogin,
  updateProfile as updateProfileApi,
  verifyGoogleToken as onVerifyGoogleToken,
} from 'utils/apis'
import {
  resetInits,
  setCompanies,
  setInits,
  setLoadedInit,
  setOptions,
  setUser,
  startInit,
  stopInit,
} from 'redux/inits/actions'
import * as actionTypes from 'redux/actionTypes'
import { removeToken, setSavedEmail, setToken } from 'utils/cookies'
import { setSelectedEndpoint } from 'redux/companies/actions'
import { setErrorLoginForm } from 'redux/loginForm/actions'

const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  ACTIVATE: 'user/ACTIVATE',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
}

/**
 * Show login modal
 */
export const showLoginForm = (errorMessage) => {
  const payload = {
    showLoginForm: true,
  }
  if (errorMessage) {
    payload.message = errorMessage
  }
  return {
    type: actionTypes.SHOW_LOGIN_FORM,
    payload,
  }
}

/**
 * Hide login modal
 */
export const hideLoginForm = () => {
  return {
    type: actionTypes.SHOW_LOGIN_FORM,
    payload: {
      showLoginForm: false,
      message: '',
    },
  }
}

export const loginRedux = (payload) => {
  return {
    type: actionTypes.USER_LOGIN,
    payload,
  }
}

export const tokenExpiredRedux = () => {
  return {
    type: actionTypes.TOKEN_EXPIRED,
  }
}

export const loginSuccessRedux = (payload) => {
  return {
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload,
  }
}

export const loginFailureRedux = (payload) => {
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    payload,
  }
}

export const setUserRedux = (payload) => {
  return {
    type: actionTypes.SET_USER,
    payload,
  }
}

export const login = (payload) => {
  return (dispatch) => {
    dispatch(loginRedux(payload))

    return onLogin(payload.email, payload.password, payload.remember_me)
      .then((response) => {
        if (response.data.success) {
          const { init, data } = response.data.data
          setToken(data.access_token, payload.remember_me)
          dispatch(setInits(init))
        } else {
          message.error(response.data.message)
        }
      })
      .catch(() => {
        dispatch(loginFailureRedux())
      })
  }
}

export const afterLoginHandler = (response, payload, withLoading) => {
  return (dispatch) => {
    if (withLoading) {
      dispatch(loginRedux(payload))
    }

    if (response.data.success) {
      const { init, data, companies, user } = response.data.data
      if (payload.email) {
        setSavedEmail(payload.email)
      }
      setToken(data.access_token)
      if (init) {
        dispatch(setSelectedEndpoint(init.endpoint))
        dispatch(setInits(init))
        // dispatch(stopInit());
      } else {
        if (response.data?.data?.endpoint) {
          dispatch(setSelectedEndpoint(response.data?.data?.endpoint))
        }
        dispatch(setUser({ user }))
        dispatch(setCompanies({ companies }))
      }
      dispatch(stopInit())
      dispatch(setLoadedInit())
    } else {
      message.error(response.data.message)
    }
  }
}

export const singleLogin = (payload) => {
  return (dispatch) => {
    dispatch(loginRedux(payload))
    dispatch(setErrorLoginForm(null))

    return onSingleLogin(payload.email, payload.password, payload.remember_me)
      .then((response) => {
        dispatch(afterLoginHandler(response, payload))
      })
      .catch(() => {
        dispatch(loginFailureRedux())
      })
  }
}

export const verifyGoogleToken = (payload) => {
  return (dispatch) => {
    dispatch(loginRedux())
    dispatch(setErrorLoginForm(null))

    return onVerifyGoogleToken(payload)
      .then((response) => {
        if (response.data.success) {
          dispatch(afterLoginHandler(response, { email: response.data.data.user.email }))
        } else {
          dispatch(setErrorLoginForm(response.data.message))
        }
      })
      .catch((e) => {
        dispatch(loginFailureRedux())
        if (e && e.data && e.data.message) {
          dispatch(setErrorLoginForm(e.data.message))
        }
      })
  }
}

export const invite = (payload) => {
  return (dispatch) => {
    return onInvite(payload)
      .then((response) => {
        if (response.data.success) {
          const { init, data } = response.data.data
          store.remove('app.endpoint')
          setToken(data.access_token, data.expires_at)
          dispatch(setInits(init))
          dispatch(stopInit())
          return true
        }
        message.error(response.data.message)
        return false
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const acceptInvitation = (payload) => {
  return (dispatch) => {
    return onAcceptInvitation(payload)
      .then((response) => {
        if (response.data.success) {
          const { init, data } = response.data.data
          store.remove('app.endpoint')
          setToken(data.access_token, data.expires_at)
          dispatch(setInits(init))
          dispatch(stopInit())
          return true
        }
        message.error(response.data.message)
        return false
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const reset = ({ payload, onFinish }) => {
  return (dispatch) => {
    return onReset(payload)
      .then((response) => {
        if (response.data.success) {
          const { init, data, companies, user } = response.data.data
          store.remove('app.endpoint')
          setToken(data.access_token, data.expires_at)
          if (init) {
            dispatch(setInits(init))
          } else {
            dispatch(setUser({ user }))
            dispatch(setCompanies({ companies }))
          }
          dispatch(stopInit())
        } else {
          message.error(response.data.message)
        }
        onFinish()
      })
      .catch((e) => {
        console.log(e)
        message.error('Error reset')
        onFinish()
      })
  }
}

export const logoutRedux = () => {
  return {
    type: actionTypes.USER_LOGOUT,
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch(startInit())
    dispatch(hideLoginForm())
    return onLogout()
      .then((response) => {
        if (response.data.success) {
          // Dihapus dulu previous path dan selected endpoinnya
          store.remove('app.previousPathname')
          store.remove('app.endpoint')
          // Dihapus tokennya
          removeToken()
          // Reset reduxnya
          dispatch(setSelectedEndpoint(null))
          dispatch(logoutRedux())
          dispatch(resetInits())
          dispatch(setOptions(response.data.data))
        } else {
          message.error(response.data.message)
        }
        dispatch(stopInit())
      })
      .catch(() => {
        dispatch(loginFailureRedux())
        dispatch(stopInit())
      })
  }
}

export const updateProfileRedux = (payload) => {
  return {
    type: actionTypes.USER_UPDATE_PROFILE,
    payload,
  }
}

export const updateProfile = (payload) => {
  return (dispatch) => {
    return updateProfileApi(payload).then((response) => {
      dispatch(updateProfileRedux(response.data.data))
      return response
    })
  }
}

export const tokenExpired = () => {
  return (dispatch) => {
    // Dihapus dulu previous path dan selected endpoinnya
    store.remove('app.previousPathname')
    store.remove('app.endpoint')
    // Dihapus tokennya
    removeToken()
    dispatch(setSelectedEndpoint(null))
    dispatch(resetInits())
    dispatch(tokenExpiredRedux())
  }
}

export default actions
