import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'

import {
  executeImportFinanceFixedAsset,
  getFinanceFixedAssetAccumulatedDepreciation,
  getFinanceFixedAssets,
  getFinanceFixedAssetsAssetDisposalStats,
  getFinanceFixedAssetsDepreciationStats,
  getFinanceFixedAssetsTopAcquisitionPriceStats,
  getFinanceFixedAssetsTopAssetValueStats,
  getFinanceFixedAssetValueStats,
  uploadImportFinanceFixedAsset,
} from 'utils/apis'

export const useFixedAssets = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['fixedAsset', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssets(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
    },
  )
}

export const useFixedAssetAccumulatedDepreciation = (params = {}, options = {}) => {
  return useQuery(
    ['fixedAssetAccumulatedDepreciation', params],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssetAccumulatedDepreciation(params)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useFixedAssetsTopAcquisitionPriceStats = ({ options = {} } = {}) => {
  return useQuery(
    ['fixedAssetTopAcquisitionPriceStats'],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssetsTopAcquisitionPriceStats()
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useFixedAssetsTopAssetValueStats = ({ options = {} } = {}) => {
  return useQuery(
    ['fixedAssetTopAssetValueStats'],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssetsTopAssetValueStats()
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useFixedAssetsAssetValueStats = ({ payload = {}, options = {} } = {}) => {
  return useQuery(
    ['fixedAssetAssetValueStats', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssetValueStats(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useFixedAssetsAssetsDepreciationStats = ({ payload = {}, options = {} } = {}) => {
  return useQuery(
    ['fixedAssetAssetsDepreciationStats', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssetsDepreciationStats(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useFixedAssetsAssetDisposalStats = ({ payload = {}, options = {} } = {}) => {
  return useQuery(
    ['fixedAssetAssetDisposalStats', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceFixedAssetsAssetDisposalStats(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

// export function useFixedAsset({ id, enabled = false }) {
//   return useQuery(
//     ['fixedAsset', id],
//     async () => {
//       let response
//       try {
//         const {
//           data: { data },
//         } = await getFinanceFixedAsset(id)
//         response = data
//       } catch (error) {
//         throw new Error('Failed to load data from server!')
//       }
//       return response
//     },
//     {
//       enabled: !!id && enabled,
//       onError: (error) =>
//         message.error((error && error.message) || 'Failed to load data from server!'),
//     },
//   )
// }

export const useUploadImportFinanceFixedAssetMutation = () => {
  return useMutation(uploadImportFinanceFixedAsset)
}

export const useExecuteImportFinanceFixedAssetMutation = () => {
  return useMutation(executeImportFinanceFixedAsset)
}
