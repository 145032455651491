import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
import {
  addBudgetReports,
  agedPayableDetailReports,
  agedPayableReports,
  agedReceivableDetailReports,
  agedReceivableReports,
  attachmentsReports,
  budgetDetailReports,
  budgetProfitLossDetailReports,
  budgetProfitLossReports,
  budgetReports,
  customerInvoiceDetailReports,
  customerInvoiceReports,
  deleteBudgetReports,
  editBudgetReports,
  equityMovementReports,
  expenseClaimDetailReports,
  expenseClaimReports,
  expensePerContactReports,
  exportsReports,
  financeReportingsGraphs,
  fixedAssetDetailReports,
  fixedAssetReleaseReports,
  fixedAssetReports,
  generalLedgerAccountsDetailReports,
  generalLedgerAccountsReports,
  generalLedgerReports,
  generalLedgerStatsReports,
  getProductPurchasesContacts,
  getProductPurchasesContactsDetails,
  getProductSalesContacts,
  getProductSalesContactsDetails,
  getSalesPerProductCategory,
  importsDetailReports,
  importsReports,
  importsTransactionsReports,
  incomePerCustomerDetailReports,
  incomePerCustomerReports,
  inventoryStockAdjustmentDetailReports,
  inventoryStockAdjustmentReports,
  inventoryStockMovementDetailFifoReports,
  inventoryStockMovementDetailReports,
  inventoryStockMovementFifoReports,
  inventoryStockMovementReports,
  inventorySummaryDetailFifoReports,
  inventorySummaryFifoReports,
  inventorySummaryReports,
  inventoryWarehouseTransferDetailReports,
  inventoryWarehouseTransferReports,
  invoicePaymentReports,
  orderPerProductReports,
  productionReports,
  productProfitabilityReports,
  profitabilityPerInvoiceGrandTotalReports,
  profitabilityPerInvoiceReports,
  profitLossReports,
  purchaseDeliveryReports,
  purchaseDetailReports,
  purchaseInvoicePaymentReports,
  purchasesPerPeriodReports,
  purchasesPerProductReports,
  purchasesPerVendorDetailReports,
  purchasesPerVendorReports,
  receivablePayableReports,
  salesDeliveryReports,
  salesDetailReports,
  salesPerPeriodReports,
  salesPerPersonDailyReports,
  salesPerPersonReports,
  salesPerProductReports,
  salesTaxDetailReports,
  salesTaxReports,
  shippingCostPerExpeditionDailyReports,
  shippingCostPerExpeditionReports,
  supplierInvoiceReports,
  warehouseStockSummaryReports,
  witholdingTaxReports,
} from 'utils/apis'

export const useFinanceReportingsGraphs = ({ payload = {}, extraQueryKey = [], options = {} }) => {
  return useQuery(
    ['financeReportingsGraphs', payload, ...extraQueryKey],
    async () => {
      const {
        data: { data },
      } = await financeReportingsGraphs(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useGetProductSalesContactsDetails = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['getProductSalesContactDetails', payload],
    async () => {
      const {
        data: { data },
      } = await getProductSalesContactsDetails(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
    },
  )
}

export const useGetProductPurchasesContactsDetails = ({ payload = {}, enabled = false }) => {
  return useQuery(
    [`getProductPurchasesContactDetails`, payload],
    async () => {
      const {
        data: { data },
      } = await getProductPurchasesContactsDetails(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
    },
  )
}

export const useGetProductSalesContacts = ({ payload, enabled = false }) => {
  return useQuery(
    [`getProductSalesContacts`, payload],
    async () => {
      const {
        data: { data },
      } = await getProductSalesContacts(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
    },
  )
}

export const useSalesTaxDetailReport = (payload, options = {}) => {
  return useQuery(
    [`salesTaxDetailReports`, payload],
    async () => {
      const {
        data: { data },
      } = await salesTaxDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useInventoryStockAdjustmentReport = ({ payload, options = {} } = {}) => {
  return useQuery(
    ['inventoryStockAdjustmentReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryStockAdjustmentReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useInventoryStockAdjustmentDetailReport = ({ payload, options = {} } = {}) => {
  return useQuery(
    ['inventoryStockAdjustmentDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryStockAdjustmentDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useInventoryWarehouseTransferReport = ({ payload, options = {} } = {}) => {
  return useQuery(
    ['inventoryWarehouseTransferReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryWarehouseTransferReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

export const useInventoryWarehouseTransferDetailReport = ({ payload, options = {} } = {}) => {
  return useQuery(
    ['inventoryWarehouseTransferDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryWarehouseTransferDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed data from server!'),
      ...options,
    },
  )
}

// Taxes

export const useSalesTaxReport = ({ payload, options = {} }) => {
  return useQuery(
    ['salesTaxReports', payload],
    async () => {
      const {
        data: { data },
      } = await salesTaxReports(payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useWitholdingTaxReport = ({ payload, options = {} }) => {
  return useQuery(
    ['witholdingTaxReports', payload],
    async () => {
      const {
        data: { data },
      } = await witholdingTaxReports(payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

// Expenses

export const useExpensePerContactReport = ({ payload, options = {} }) => {
  return useQuery(
    ['expensePerContactReports', payload],
    async () => {
      const {
        data: { data },
      } = await expensePerContactReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useExpenseClaimReport = ({ payload, options = {} }) => {
  return useQuery(
    ['expenseClaimReports', payload],
    async () => {
      const {
        data: { data },
      } = await expenseClaimReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useExpenseClaimDetailReports = ({ payload, options = {} }) => {
  return useQuery(
    ['expenseClaimDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await expenseClaimDetailReports(payload.id, payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

// Fixed Assets

export const useFixedAssetReports = ({ payload, options = {} }) => {
  return useQuery(
    ['fixedAssetReports', payload],
    async () => {
      const {
        data: { data },
      } = await fixedAssetReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useFixedAssetDetailReport = ({ payload, options = {} }) => {
  return useQuery(
    ['fixedAssetDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await fixedAssetDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useFixedAssetReleaseReport = ({ payload, options = {} }) => {
  return useQuery(
    ['fixedAssetReleaseReports', payload],
    async () => {
      const {
        data: { data },
      } = await fixedAssetReleaseReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

// Others

export const useAttachmentsReport = ({ payload, enabled = false }) => {
  return useQuery(
    ['attachmentsReports', payload],
    async () => {
      const {
        data: { data },
      } = await attachmentsReports(payload)
      return data
    },
    {
      enabled,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
    },
  )
}

export const useExportsReport = ({ payload, enabled = false }) => {
  return useQuery(
    ['exportsReports', payload],
    async () => {
      const {
        data: { data },
      } = await exportsReports(payload)
      return data
    },
    {
      enabled,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
    },
  )
}

export const useImportsReport = ({ payload, enabled = false }) => {
  return useQuery(
    ['importsReports', payload],
    async () => {
      const {
        data: { data },
      } = await importsReports(payload)
      return data
    },
    {
      enabled,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
    },
  )
}

export const useImportsDetailReport = ({ payload, enabled = false }) => {
  return useQuery(
    ['importsDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await importsDetailReports(payload)
      return data
    },
    {
      enabled,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
    },
  )
}

export const useImportsTransactionsReport = ({ payload, enabled = false }) => {
  return useQuery(
    ['importsTransactionsReports', payload],
    async () => {
      const {
        data: { data },
      } = await importsTransactionsReports(payload)
      return data
    },
    {
      enabled,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
    },
  )
}

// inventory

export const useInventorySummaryReport = ({ payload, options }) => {
  return useQuery(
    ['inventorySummaryReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventorySummaryReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInventorySummaryFifoReports = ({ payload, options }) => {
  return useQuery(
    ['inventorySummaryFifoReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventorySummaryFifoReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInventorySummaryDetailFifoReports = ({ payload, options }) => {
  return useQuery(
    ['inventorySummaryDetailFifoReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventorySummaryDetailFifoReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useWarehouseStockSummaryReports = ({ payload, options }) => {
  return useQuery(
    ['warehouseStockSummaryReports', payload],
    async () => {
      const {
        data: { data },
      } = await warehouseStockSummaryReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProductionReport = ({ payload, options }) => {
  return useQuery(
    ['productionReports', payload],
    async () => {
      const {
        data: { data },
      } = await productionReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInventoryStockMovementReport = ({ payload, options }) => {
  return useQuery(
    ['inventoryStockMovementReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryStockMovementReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInventoryStockMovementDetailReport = ({ payload, options }) => {
  return useQuery(
    ['inventoryStockMovementDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryStockMovementDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInventoryStockMovementFifoReport = ({ payload, options }) => {
  return useQuery(
    ['inventoryStockMovementFifoReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryStockMovementFifoReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInventoryStockMovementDetailFifoReport = ({ payload, options }) => {
  return useQuery(
    ['inventoryStockMovementDetailFifoReports', payload],
    async () => {
      const {
        data: { data },
      } = await inventoryStockMovementDetailFifoReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useBudgetReport = ({ payload, options }) => {
  return useQuery(
    ['budgetReports', payload],
    async () => {
      const {
        data: { data },
      } = await budgetReports(payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useAddBudgetReport = (options = {}) => {
  return useMutation((payload) => addBudgetReports(payload), options)
}

export const useBudgetDetailReport = ({ payload, options }) => {
  return useQuery(
    ['budgetDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await budgetDetailReports(payload.id, payload.payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useEditBudgetReport = (options = {}) => {
  return useMutation((payload) => editBudgetReports(payload.id, payload.payload), options)
}

export const useDeleteBudgetReport = (options = {}) => {
  return useMutation((payload) => deleteBudgetReports(payload), options)
}

export const useBudgetProfitLossReport = ({ payload, options }) => {
  return useQuery(
    ['budgetProfitLossReports', payload],
    async () => {
      const {
        data: { data },
      } = await budgetProfitLossReports(payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useBudgetProfitLossDetailReport = ({ payload, options }) => {
  return useQuery(
    ['budgetProfitLossDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await budgetProfitLossDetailReports(payload.id, payload.payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesDetailReport = ({ payload, options }) => {
  return useQuery(
    ['salesDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await salesDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchaseDetailReport = ({ payload, options }) => {
  return useQuery(
    ['purchaseDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchaseDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useAgedReceivableReport = ({ payload, options }) => {
  return useQuery(
    ['agedReceivableReports', payload],
    async () => {
      const {
        data: { data },
      } = await agedReceivableReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useAgedReceivableDetailReport = ({ payload, options }) => {
  return useQuery(
    ['agedReceivableDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await agedReceivableDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useAgedPayableReport = ({ payload, options }) => {
  return useQuery(
    ['agedPayableReports', payload],
    async () => {
      const {
        data: { data },
      } = await agedPayableReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useAgedPayableDetailReport = ({ payload, options }) => {
  return useQuery(
    ['agedPayableDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await agedPayableDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useCustomerInvoiceReport = ({ payload, options }) => {
  return useQuery(
    ['customerInvoiceReports', payload],
    async () => {
      const {
        data: { data },
      } = await customerInvoiceReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useCustomerInvoiceDetailReport = ({ payload, options }) => {
  return useQuery(
    ['customerInvoiceDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await customerInvoiceDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSupplierInvoiceReport = ({ payload, options }) => {
  return useQuery(
    ['supplierInvoiceReports', payload],
    async () => {
      const {
        data: { data },
      } = await supplierInvoiceReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesPerProductReport = ({ payload, additionalKeys, options }) => {
  return useQuery(
    ['salesPerProductReports', payload, additionalKeys],
    async () => {
      const {
        data: { data },
      } = await salesPerProductReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useOrderPerProductReport = ({ payload, additionalKeys, options }) => {
  return useQuery(
    ['orderPerProductReports', payload, additionalKeys],
    async () => {
      const {
        data: { data },
      } = await orderPerProductReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProductProfitabilityReport = ({ payload, options }) => {
  return useQuery(
    ['productProfitabilityReports', payload],
    async () => {
      const {
        data: { data },
      } = await productProfitabilityReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchasesPerProductReport = ({ payload, options }) => {
  return useQuery(
    ['purchasesPerProductReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchasesPerProductReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useIncomePerCustomerReport = ({ payload, options }) => {
  return useQuery(
    ['incomePerCustomerReports', payload],
    async () => {
      const {
        data: { data },
      } = await incomePerCustomerReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useIncomePerCustomerDetailReport = ({ payload, options }) => {
  return useQuery(
    ['incomePerCustomerDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await incomePerCustomerDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchasesPerVendorReport = ({ payload, options }) => {
  return useQuery(
    ['purchasesPerVendorReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchasesPerVendorReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchasesPerVendorDetailReport = ({ payload, options }) => {
  return useQuery(
    ['purchasesPerVendorDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchasesPerVendorDetailReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProfitabilityPerInvoiceReport = ({ payload, options }) => {
  return useQuery(
    ['profitabilityPerInvoiceReports', payload],
    async () => {
      const {
        data: { data },
      } = await profitabilityPerInvoiceReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProfitabilityPerInvoiceGrandTotalReport = ({ payload, options }) => {
  return useQuery(
    ['profitabilityPerInvoiceGrandTotalReports'],
    async () => {
      const {
        data: { data },
      } = await profitabilityPerInvoiceGrandTotalReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesPerPersonReport = ({ payload, options }) => {
  return useQuery(
    ['salesPerPersonReports', payload],
    async () => {
      const {
        data: { data },
      } = await salesPerPersonReports(payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesPerPersonDailyReport = ({ payload, options }) => {
  return useQuery(
    ['salesPerPersonDailyReports', payload],
    async () => {
      const {
        data: { data },
      } = await salesPerPersonDailyReports(payload)
      return data
    },
    {
      keepPreviousData: true,
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesPerPeriodReport = ({ payload, options }) => {
  return useQuery(
    ['salesPerPeriodReports', payload],
    async () => {
      const {
        data: { data },
      } = await salesPerPeriodReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchasesPerPeriodReport = ({ payload, options }) => {
  return useQuery(
    ['purchasesPerPeriodReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchasesPerPeriodReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesPerProductCategoryReport = ({ payload, options }) => {
  return useQuery(
    ['getSalesPerProductCategory', payload],
    async () => {
      const {
        data: { data },
      } = await getSalesPerProductCategory(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProductPurchasesContactsReport = ({ payload, options }) => {
  return useQuery(
    ['getProductPurchasesContacts', payload],
    async () => {
      const {
        data: { data },
      } = await getProductPurchasesContacts(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProductSalesContactsReport = ({ payload, options }) => {
  return useQuery(
    ['getProductSalesContacts', payload],
    async () => {
      const {
        data: { data },
      } = await getProductSalesContacts(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useInvoicePaymentReport = ({ payload, options }) => {
  return useQuery(
    ['invoicePaymentReports', payload],
    async () => {
      const {
        data: { data },
      } = await invoicePaymentReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchaseInvoicePaymentReport = ({ payload, options }) => {
  return useQuery(
    ['purchaseInvoicePaymentReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchaseInvoicePaymentReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useSalesDeliveryReport = ({ payload, options }) => {
  return useQuery(
    ['salesDeliveryReports', payload],
    async () => {
      const {
        data: { data },
      } = await salesDeliveryReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const usePurchaseDeliveryReport = ({ payload, options }) => {
  return useQuery(
    ['purchaseDeliveryReports', payload],
    async () => {
      const {
        data: { data },
      } = await purchaseDeliveryReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useShippingCostPerExpeditionReport = ({ payload, options }) => {
  return useQuery(
    ['shippingCostPerExpeditionReports', payload],
    async () => {
      const {
        data: { data },
      } = await shippingCostPerExpeditionReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useShippingCostPerExpeditionDailyReport = ({ payload, options }) => {
  return useQuery(
    ['shippingCostPerExpeditionDailyReports', payload],
    async () => {
      const {
        data: { data },
      } = await shippingCostPerExpeditionDailyReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useReceivablePayableReport = ({ payload, options }) => {
  return useQuery(
    ['receivablePayableReports', payload],
    async () => {
      const {
        data: { data },
      } = await receivablePayableReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useEquityMovementReport = ({ payload, options }) => {
  return useQuery(
    ['equityMovementReports', payload],
    async () => {
      const {
        data: { data },
      } = await equityMovementReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useProfitLossReport = ({ payload, options }) => {
  return useQuery(
    ['profitLossReports', payload],
    async () => {
      const {
        data: { data },
      } = await profitLossReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useGeneralLedgerStatsReport = ({ payload, options }) => {
  return useQuery(
    ['generalLedgerStatsReports', payload],
    async () => {
      const {
        data: { data },
      } = await generalLedgerStatsReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useGeneralLedgerReport = ({ payload, options = {} }) => {
  return useQuery(
    ['generalLedgerReports', payload],
    async () => {
      const {
        data: { data },
      } = await generalLedgerReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useGeneralLedgerAccountsReport = ({ payload, options = {} }) => {
  return useQuery(
    ['generalLedgerAccountsReports', payload],
    async () => {
      const {
        data: { data },
      } = await generalLedgerAccountsReports(payload)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}

export const useGeneralLedgerAccountsDetailReport = ({ payload, options = {} }) => {
  return useQuery(
    ['generalLedgerAccountsDetailReports', payload],
    async () => {
      const {
        data: { data },
      } = await generalLedgerAccountsDetailReports(payload.accountId, payload.params)
      return data
    },
    {
      onError: (error) => message.error(error?.data?.message || 'Failed from server!'),
      ...options,
    },
  )
}
