import React from 'react'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { DatePicker as DateAntd } from 'antd'
import { formatDateText } from 'utils/formatting'

const DatePicker = ({intl, ...props}) => {
  return(
    <DateAntd
      format={formatDateText()}
      showToday={false}
      presets={[
        {
          label: intl.formatMessage({ id: 'date.today' }),
          value: dayjs(),
        },
        {
          label: intl.formatMessage({ id: 'date.yesterday' }),
          value: dayjs().add(-1, 'd'),
        },
        {
          label: intl.formatMessage({ id: 'date.last_week' }),
          value: dayjs().add(-7, 'd'),
        },
        {
          label: intl.formatMessage({ id: 'date.last_month' }),
          value: dayjs().add(-1, 'month'),
        },
        {
          label: 'Tahun Lalu',
          value: dayjs().add(-1, 'year'),
        },
      ]}
      {...props}
    />
  )
}

export default connect()(injectIntl(DatePicker))
