import axios from 'axios'
import { stringify } from 'query-string'
import { cleanBlankValue, getOnce, openNewTabBlobFile } from 'utils/helper'

export const getFinancePurchaseOrders = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/purchaseOrders?${query}`)
}

export const addFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders', data)
}

export const editFinancePurchaseOrder = (data) => {
  return axios.put(`/finance/purchaseOrders/${data.id}`, data)
}

export const deleteOnAddFinancePurchaseOrderAttachment = (url) => {
  return axios.delete('/finance/purchaseOrders/attachments', { data: { url } })
}

export const deleteOnEditFinancePurchaseOrderAttachment = (id, url) => {
  return axios.delete(`/finance/purchaseOrders/${id}/attachments`, { data: { url } })
}

export const getFinancePurchaseOrder = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseOrders/${id}${query}`)
}

export const getFinancePurchaseOrderLog = (id, params) => {
  const query = params ? `?${stringify(params)}` : ''
  return axios.get(`/finance/purchaseOrders/${id}/logs${query}`)
}

export const getSuggestionFinanceProduct = (params, options) => {
  const query = stringify(params)

  return getOnce(`/finance/products/suggestionPerPage?${query}`, options)
}

export const changeFinancePurchaseOrderStatus = (id, data) => {
  return axios.patch(`/finance/purchaseOrders/${id}/status`, data)
}

export const deleteFinancePurchaseOrder = (id) => {
  return axios.delete(`/finance/purchaseOrders/${id}`)
}

export const uploadImportFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/uploadImport', data)
}

export const executeImportFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/executeImport', data)
}

export const getFinancePurchaseOrderJournal = (id) => {
  return axios.get(`/finance/transactions/${id}/journals`)
}

export const createPdfFinancePurchaseOrder = (id) => {
  return axios
    .get(`/finance/purchaseOrders/${id}/print`, { responseType: 'blob' })
    .then((response) => {
      openNewTabBlobFile(response)

      return Promise.resolve(true)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const getFinancePurchaseOrderPublicUrl = (id) => {
  return axios.get(`/finance/purchaseOrders/${id}/publicUrl`)
}

export const exportFinancePurchaseOrder = (params) => {
  const URL =
    params.status_id === 'recurring' ? 'purchaseOrders/recurring/export' : 'purchaseOrders/export'
  if (params.status_id === 'recurring') {
    delete params.status_id
  }
  const query = stringify(cleanBlankValue(params))
  return axios.get(`/finance/${URL}?${query}`, { responseType: 'arraybuffer' })
}

export const getFinancePurchaseOrderWaTemplate = (id) => {
  return axios.get(`/finance/purchaseOrders/${id}/whatsapp`)
}

export const getFinancePurchaseOrderEmailTemplate = (id) => {
  return axios.get(`/finance/purchaseOrders/${id}/email`)
}

export const sendFinancePurchaseOrderEmail = (id, data) => {
  return axios.post(`/finance/purchaseOrders/${id}/email`, data)
}

export const addRecurringPurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/recurring', data)
}

export const editRecurringPurchaseOrder = (data) => {
  return axios.put(`/finance/purchaseOrders/recurring/${data.id}`, data)
}

export const getRecurringPurchaseOrder = (id) => {
  return axios.get(`/finance/purchaseOrders/recurring/${id}`)
}

export const deleteRecurringPurchaseOrder = (id) => {
  return axios.delete(`/finance/purchaseOrders/recurring/${id}`)
}

export const getRecurringPurchaseOrders = (params) => {
  const query = stringify(params)
  return getOnce(`/finance/purchaseOrders/recurring?${query}`)
}

export const getFinancePurchaseOrderSmsTemplate = (id) => {
  return axios.get(`/finance/purchaseOrders/${id}/sms`)
}

export const sendFinancePurchaseOrderSms = (id, data) => {
  return axios.post(`/finance/purchaseOrders/${id}/sms`, data)
}

export const inputMassDeleteFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/inputMassDelete', data)
}

export const executeMassDeleteFinancePurchaseOrder = (data) => {
  return axios.delete('/finance/purchaseOrders/executeMassDelete', { data })
}

export const inputMassSendEmailFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/inputMassEmail', data)
}

export const executeMassSendEmailFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/executeMassEmail', data)
}

export const getMassDownloadFinancePurchaseOrder = (params) => {
  return axios.get('/finance/purchaseOrders/massDownload', { params })
}

export const inputMassCreateFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/inputMassPurchaseOrder', data)
}

export const executeMassCreateFinancePurchaseOrder = (data) => {
  return axios.post('/finance/purchaseOrders/executeMassPurchaseOrder', data)
}

export const approveFinancePurchaseOrder = ({ id }) => {
  return axios.post(`/finance/purchaseOrders/${id}/approve`)
}

export const rejectFinancePurchaseOrder = ({ id }) => {
  return axios.post(`/finance/purchaseOrders/${id}/reject`)
}

export const revertApprovalFinancePurchaseOrder = ({ id }) => {
  return axios.post(`/finance/purchaseOrders/${id}/revert`)
}
