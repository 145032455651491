import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'

import { createHashHistory } from 'history'
import axios from 'axios'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ConfigProvider from 'components/LayoutComponents/ConfigProvider'
import { generateBaseUri } from 'utils/helper'
import { errorHandler, requestHandler, successHandler } from 'utils/axios'
import App from 'app'
import { getToken } from 'utils/cookies'
import { storedSettings } from 'utils/localStorage'
import setupSentry from 'utils/sentry'
import configureStore from 'redux/store'
import { AppProvider } from 'components/AppProvider'
import { initializeFp, initializeTagManager, trackingPage } from 'utils/gtm'
import createIcons from './iconLibraries'

// app styles
import './global.scss'

setupSentry()
const history = createHashHistory()

if (process.env.NODE_ENV === 'production') {
  // Initialize tracker
  initializeTagManager()
  initializeFp()
}

export const store = configureStore(history)

axios.defaults.baseURL = generateBaseUri()
axios.defaults.headers.Authorization = `Bearer ${getToken()}`
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-App'] = 'finance'
axios.defaults.headers.common['X-Client-Version'] = process.env.REACT_APP_VERSION
axios.defaults.timeout = 300000

axios.interceptors.request.use((request) => requestHandler(request))

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error, store, history),
)

// Use query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

createIcons()

const AppContainer = () => {
  // Ketika di production dipasang tracking
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      history.listen((location) => {
        trackingPage(location.pathname)
      })
    }
    Chart.register(
      ArcElement,
      BarElement,
      CategoryScale,
      Filler,
      LinearScale,
      LineElement,
      PointElement,

      Legend,
      Title,
      Tooltip,
    )
  }, [])

  return (
    <AppProvider
      value={{
        settings: storedSettings({
          isMobileMenuOpen: false,
          isMenuCollapsed: false,
        }),
      }}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <App history={history} />
            </GoogleOAuthProvider>
          </ConfigProvider>
        </QueryClientProvider>
      </Provider>
    </AppProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<AppContainer />);

if (window.Cypress) {
  window.store = store
}

export default { store, history }
