import React from 'react'
import { injectIntl } from 'react-intl'
import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { escapeRegExp } from 'lodash'
import { useSelector } from "react-redux";
import { darkModeSelector } from "redux/selectors";

function SearchWidget({ intl, onChange = () => {}, escape = false, autoFocus = true, ...props }) {
  const darkMode = useSelector(darkModeSelector)

  return (
    <Input
      prefix={<SearchOutlined style={{ color: darkMode ? '#B8BECA' : 'rgba(0,0,0,.25)' }} />}
      placeholder={intl.formatMessage({ id: 'input.placeholder.search' })}
      id="input-search"
      onChange={(e) => {
        if (escape) {
          e.target.value = escapeRegExp(e.target.value)
        }
        onChange(e)
      }}
      allowClear
      autoFocus={autoFocus}
      {...props}
    />
  )
}

export default injectIntl(SearchWidget)
