import React, {useCallback} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { MoonFilled, SunFilled } from '@ant-design/icons';
import store from "store";

import { optionsSelector } from 'redux/selectors'
import { useEditOptionThemeColors } from "utils/queries/options";
import { editOptionsRedux } from "redux/options/actions";

const ThemeColors = () => {
  const dispatch = useDispatch();
  const options = useSelector(optionsSelector)
  const editOptionThemeColors = useEditOptionThemeColors()

  const changeThemeHandler = useCallback(
    (value) => {
      dispatch(editOptionsRedux({ dark_mode: value ? 1 : 0 }))
      editOptionThemeColors.mutate(
        { dark_mode: value ? 1 : 0 },
        {
          onSuccess: (response) => {
            if (response.data.success) {
              if (value) {
                store.set("app.darkMode", value ? 1 : 0);
              } else {
                store.remove("app.darkMode");
              }
            } else {
              dispatch(editOptionsRedux({ dark_mode: value ? 0 : 1 }));
            }
          },
        }
      )
    },
    [dispatch, editOptionThemeColors]
  )

  return (
    <>
      <div className="flex items-center">
        <Switch
          checkedChildren={<MoonFilled />}
          unCheckedChildren={<SunFilled />}
          checked={options?.dark_mode === 1}
          onChange={changeThemeHandler}
          disabled={editOptionThemeColors.isLoading}
          loading={editOptionThemeColors.isLoading}
        />
      </div>
    </>
  )
}

export default ThemeColors
