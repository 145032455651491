import React from 'react'
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TimePicker as TimeAntd } from "antd";
import locale from "antd/es/date-picker/locale/id_ID";

const idLocale = {
  ...locale,
  lang: {
    ...locale.lang,
    now: "Sekarang",
    ok: "Ok",
  }
};

function TimePicker({ intl, ...props }) {

  return (
    <TimeAntd
      locale={intl.locale === 'id-ID' ? idLocale : undefined}
      {...props}
    />
  )
}

export default connect()(injectIntl(TimePicker))
